/* addons  */

a:hover{
  color: #fff;
}
.floatbutton{
  font-family:var(--font-primary);
}

.floatbutton .clickbutton .crossplus:after,
.floatbutton .clickbutton .crossplus:before {
  content: "";
  display: none;
  z-index: 99;
  margin: auto;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0
}

.floatbutton .ban-form input,
.floatbutton .ban-form textarea {
  padding: 10px 15px;
  outline: 0 !important
}

.floatbutton .theme-btn,
span.coupon {
  animation: .5s infinite alternate scrollDownAnimation
}

.floatbutton .banner-form h3,
.floatbutton span.coupon {
  text-transform: uppercase
}

.floatbutton.active {
  right: 0;
  z-index: 999;
  -webkit-transition: .4s;
  -moz-transition: .4s;
  -o-transition: .4s;
  transition: .4s
}
.floatbutton .clickbutton .crossplus {
  -webkit-transition: .4s;
  -moz-transition: .4s;
  -o-transition: .4s
}

.floatbutton {
  transition: .4s;
  position: fixed;
  right: -370px;
  top: 16%;
  font-size: 0;
  width: 420px;
  z-index: 999
}

.floatbutton  .banner-form,
.floatbutton .clickbutton {
  position: relative;
  display: inline-block
}

.floatbutton .clickbutton {
  border-radius: 11px 0 0 11px;
  width: 50px;
  z-index: 999;
  height: 300px;
  cursor: pointer;
  box-shadow: -20px 7px 18px -7px rgba(87, 184, 151, .09);
  padding-top: 0;
  vertical-align: top;
  background: linear-gradient(45deg, #2251fc, #955ee2);
  background: --primary-bg;
  margin-top: 198px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: Poppins, sans-serif;
  -ms-box-shadow: 0 0 40px #00000026;
  -o-box-shadow: 0 0 40px #00000026;
  /* border: 3px solid #fff; */
}

.floatbutton .clickbutton .crossplus:before {
  width: 20px;
  height: 2px;
  background: #fbb334
}

.floatbutton .clickbutton .crossplus:after {
  width: 2px;
  height: 20px;
  background: #fab334
}

.floatbutton .clickbutton .crossplus {
  display: block;
  transition: .4s;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-90deg);
  font-size: 16px;
  color: #fff;
  left: 0;
  right: 0;
  top: 0;
  white-space: pre;
  bottom: 0;
  font-weight: 700;
  z-index: 100
}

.floatbutton .clickbutton .crossplus.rotate {
  -webkit-transition: .4s;
  -moz-transition: .4s;
  -o-transition: .4s;
  transition: .4s
}

.floatbutton .clickbutton .crossplus i {
  font-size: 18px;
  color: #f43535;
  margin: 17px 0 0 15px
}

.floatbutton .banner-form {
  background: #fff;
  padding: 30px;
  z-index: 9;
  border-radius: 5px 0 0 5px;
  margin: 0;
  width: 370px;
  box-shadow: 0 0 30px #0000001f;
}



.floatbutton .ban-form input {
  width: 100%;
  margin: 0 0 15px;
  border: 1px solid #e5e5e5;
  background: #fff;
  color: gray;
  font-size: 14px;
  border-radius: 3px;
  height: 50px;
  font-weight: 400;
}

 .floatbutton .banner-form .intl-tel-input {
  width: 100%
}

 .floatbutton .banner-form h3 {
  color: #221a42;
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 500
}

 .floatbutton .ban-form input[type=submit] {
  color: #fff;
  font-weight: 600;
  border-radius: 11px;
  text-align: center;
  background: var(--primary-bg);
  padding: 10px 15px;
  margin: 20px 0 0;
  border: transparent;
  text-transform: uppercase;
  font-size: 20px;
}

 .floatbutton .ban-form textarea {
  width: 100%;
  border: 1px solid #e5e5e5;
  background: #fff;
  color: gray;
  font-size: 14px;
  border-radius: 3px;
  font-weight: 400;
  height: 120px;
  margin: 1px 0 0
}

.floatbutton .btns_wrap .call_wrap,
.floatbutton .btns_wrap .chat_wrap {
  position: absolute;
  padding: 0;
  overflow: hidden;
  box-shadow: 0 0 40px #00000026 !important;
  background: none;
}

.floatbutton .btns_wrap {
  position: fixed;
  right: -6px
}

.floatbutton .btns_wrap a:hover {
  text-decoration: none !important;
  right: -52px;
}

.floatbutton .btns_wrap .call_wrap {
  width: 295px;
  right: -240px;
  top: 126px;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -ms-transition: .3s;
  -o-transition: .3s;
  transition: .3s
}

.floatbutton .btns_wrap .call_wrap span {
  color: #333;
  font-size: 20px;
  vertical-align: middle;
  background: #fff;
  padding: 15px 20px 15px 15px
}

.floatbutton .btns_wrap .chat_wrap {
  display: block;
  right: -225px;
  width: 280px;
  top: 54px;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -ms-transition: .3s;
  -o-transition: .3s;
  transition: .3s
}



.floatbutton .btns_wrap .call_wrap span.icoo,
.floatbutton .btns_wrap .chat_wrap span.icoo {
  color: #fff;
  background: linear-gradient(45deg, #2251fc, #955ee2);
  background: --primary-bg;
  font-size: 18px;
  padding: 14px 20px 12px;
  vertical-align: middle;
  display: inline-block;
  border-radius: 9px;
}

.floatbutton .btns_wrap .chat_wrap span {
  color: #333;
  font-size: 20px;
  vertical-align: middle;
  background: #fff;
  padding: 15px 30px 15px 15px
}
.scroll-to-top {
  width: 50px;
  height: 50px;
  background: #ec790f;
  position: fixed;
  bottom: 88px;
  right: 40px;
  z-index: 99;
  text-align: center;
  -webkit-transition: .4s;
  transition: .4s;
  display: none;
  border-radius: 50%;
}

.scroll-to-top i {
  color: #fff;
  font-size: 18px;
  line-height: 50px;
  -webkit-transition: .4s;
  transition: .4s;
}

.theme-btn.bt-support-now {
  background: -moz-linear-gradient(45deg, #1ebbf0 8%, #39dfaa 100%);
  background: linear-gradient(45deg, #2251fc, #955ee2);
  bottom: 146px;
}

.theme-btn.bt-buy-now {
  background: #8ac346;
  background: -moz-linear-gradient(top, #a3d179 0, #88ba46 100%);
}

.theme-btn {
  border-radius: 40px;
  bottom: 59px;
  display: table;
  height: 66px;
  left: 30px;
  min-width: 66px;
  text-align: center;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 34px;
}

.theme-btn,
span.coupon {
  position: fixed;
  color: #fff;
}

.theme-btn,
span.coupon {
  animation: .5s infinite alternate scrollDownAnimation;
}

@keyframes scrollDownAnimation {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}
/* addons */