
@font-face{
  font-family: 'NoirBold';
  src: url('../font/NoirPro-Bold.otf');
}
@font-face{
  font-family: 'NoirHeavy';
  src:url( '../font/NoirPro-Heavy.otf');
}
@font-face{
  font-family: 'NoirLight';
  src:url( '../font/NoirPro-Light.otf');
}
@font-face{
  font-family: 'NoirMedium';
  src: url('../font/NoirPro-Medium.otf');
}
@font-face{
  font-family: 'NoirRegular';
  src:url( '../font/NoirPro-Regular.otf');
}
@font-face{
  font-family: 'NoirSemiBold';
  src: url('../font/NoirPro-SemiBold.otf');
}





@font-face{
  font-family: 'GlancyrBold';
  src:url( '../font/Glancyr-Bold.otf');
}
@font-face{
  font-family: 'GlancyrExtraLight';
  src:url( '../font/Glancyr-ExtraLight.otf');
}
@font-face{
  font-family: 'GlancyrLight';
  src: url('../font/Glancyr-Light.otf');
}
@font-face{
  font-family: 'GlancyrMedium';
  src:url( '../font/Glancyr-Medium.otf');
}
@font-face{
  font-family: 'GlancyrRegular';
  src: url('../font/Glancyr-Regular.otf');
}
@font-face{
  font-family: 'GlancyrSemiBold';
  src: url('../font/Glancyr-SemiBold.otf');
}