 /* PopUp Modal Css Start Here */
    .modaL_order {}

    #staticBackdrop .modal-content {
        background: #fff;
        position: relative;
        z-index: 1;
    font-family: 'GlancyrBold';
    }

    #staticBackdrop .modal-content::before {
        position: absolute;
        top: -17%;
        left: -13%;
        background: #071422;
        width: 500px;
        height: 500px;
        border-radius: 100%;
        z-index: -1;
        margin: 0 auto 0 0;
        opacity: 0.9;
        filter: blur(63px);
    }

    #staticBackdrop .modal-content::after {
        content: '';
        position: absolute;
        bottom: -18%;
        right: -20%;
        background: #e67aa8;
        width: 400px;
        height: 400px;
        border-radius: 100%;
        margin: 0 0 0 auto;
        opacity: 0.9;
        filter: blur(94px);
    }

    #staticBackdrop .modal-content .btn-close {
        position: absolute;
        right: 4px;
        top: 5px;
        background: linear-gradient(45deg, #2251fc, #955ee2);
        width: 15px;
        height: 15px;
        line-height: 13px;
        border-radius: 0px 5px 0px 10px;
        outline: none;
        box-shadow: none;
        cursor: pointer;
        z-index: 4;
        opacity: 1;
    }

    #staticBackdrop .modal-content .btn-close i {
        color: #ffffff;
        font-weight: 600;
    }

    #staticBackdrop .modal-dialog {
        max-width: 700px !important;
        margin: 150px auto 0;
    }

    #staticBackdrop .modal-header {
        padding: 0px;
        border-bottom: none;
    }

    #staticBackdrop .modal-body {
        padding: 20px 20px;
    }

    #staticBackdrop .modal-body .modal-body-img1 img {
        width: 100%;
    }

    #staticBackdrop .modal-body .modal-body-img2 img {
        width: 74%;
        position: absolute;
        right: -78%;
        top: 50%;
        transform: translate(-50%,-50%);
        z-index: 2;
    }

    #staticBackdrop .modal-body .modal-body-form {
        position: relative;
        z-index: 2;
    }

    .modal-body-form h3 {
        font-size: 46px;
        line-height: 51px;
        color: #091e34;
        margin: 20px 0;
        font-weight: 700;
    }

    .modal-body-form h3 span {
     
    }

    .modal-body-form .popup-form-list {}

    .modal-body-form .popup-form-list .form-group {
        margin: 0 0 10px 0;
    }

    .modal-body-form .popup-form-list .form-group.form-group-last {
        margin: 0;
    }

    .modal-body-form .popup-form-list .form-group input {
        background: #e9e9e9;
        color: #392657;
        font-size: 18px;
        width: 100%;
        border: 1px solid #fff;
        border-radius: 15px;
        padding: 10px 15px;
        font-family: 'NoirRegular';
    }

    .modal-body-form .popup-form-list .form-group input::placeholder {
        color: #392657;
        font-size: 18px;
    }

    .modal-body-form .popup-form-list .form-group textarea {
        background: #e9e9e9;
        color: #392657;
        font-size: 18px;
        width: 100%;
        height: 120px;
        resize: none;
        border: 1px solid #fff;
        border-radius: 15px;
        font-family: 'NoirRegular';
        padding: 10px 15px;
    }

    .modal-body-form .popup-form-list .form-group textarea::placeholder {
        color: #392657;
        font-size: 18px;
    }

    .modal-body-form .popup-form-list .form-group button.submit-btn {
        background: linear-gradient(45deg, #2251fc, #955ee2);
        color: #fff;
        font-size: 18px;
        width: 100%;
        border: 2px solid #3a54f7;
        border-radius: 15px;
        padding: 10px 10px;
        margin: 0;
        text-transform: uppercase;
        outline: none;
        transition: all .3s ease-in-out;
    }

    .modal-body-form .popup-form-list .form-group button.submit-btn:hover {
        background: var(--primary-bg);
        color: #ffffff;
        border: 2px solid #46302e;
    }

    .modal.show {
   padding-right: 0px !important;
    background: rgba( 255, 255, 255, 0.25 );
    backdrop-filter: blur( 16px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;    }


    /* PopUp Modal Css End Here */