@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import './css/utilities';
@import './css/addons.css';
@import './css/modal.css';
@import './css/font.css';
@import './css/about.css';
@import './css/services.css';
@import './css/blog.css';
@import './css/contact.css';



/* header  */
.top_bar {
    background: var(--primary-bg);
    font-family: NoirLight;
    padding: 15px 0;
}
.top_bar ul{
    display: flex;
}
.top_bar ul li{
    margin: 0 39px 0 0;
}
.top_bar ul li a{
    font-size: 18px;
    color: #fff;
    letter-spacing: 1px;
    font-weight: 400;
}
.top_bar ul li a i{}
.top_bar p {
    color: #fbf27d;
    margin:  0;
    font-size: 19px;
    letter-spacing: 1px;
    font-weight: 700;
}
header{
    font-family: 'NoirLight';
    width: 100%;
    transition: all 0.4s ease-in;
    position: fixed;
    top: 8%;
}

header.sticky {
    background: #fffffff0;
    position: fixed;
    width: 100%;
    z-index: 999;
    top: 0;
    border-bottom: 2px solid #282233;
    background: rgb(255 255 255);
}
header nav{}
header nav a img{
    width: 230px;
    object-fit: cover;
}
header nav ul{
}
header nav ul li{
    margin: 0 1rem;
}
header nav ul li a.nav-link{
    font-size: 20px;
    color: #1A1A1A;;
    font-weight: 500;
}
header.sticky nav ul li a.nav-link{
    color: #1a1a1a;
}
header .call_btns{}
header .call_btns a{
    width: 189px;
    display: inline-block;
    text-align: center;
    height: 51px;
    font-weight: 700;
    line-height: 51px;
    font-size: 18px;
    color:var(--font-color);
    transition: all 0.3s ease-in;
    border-radius: 4px;
    border: 2px solid #895de51f;
}

header .call_btns a i {
    color: #4CAF50;
    font-size: 20px;
    font-weight: 600;
    transform: rotate(45deg);
    margin: 0 5px;
}
header .call_btns a:nth-child(2){
    background: var(--primary-bg);
    color: #fff;
}
header .call_btns a:hover:nth-child(2){
    background: #885de5;
}
header .call_btns a:hover{
    transform: translateY(-10px);
}
 header .dropdown-item {
    padding: 8px 14px;
    font-size: 17px;
}
 header .dropdown-item:focus, .dropdown-item:hover {
    color: #ffffff;
    background-color: #1a1a1a;
    border-radius: 11px;
    transition: all 0.3s ease-in;
}
/* header  */


/* banner_area  */
.banner_area{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 465px;
}
.banner_area .head_txt{}
.banner_area .head_txt h1{
      font-family: 'GlancyrBold';
      font-size: 69px;
      text-align: center;
      line-height: 92px;
      margin-top: 4rem;
      color: var(--primary-bg);
}
.banner_area .head_txt h1 span{
    display: block;
}
.banner_area .head_txt h1 span b{
    color: var(--shade-color);
    text-decoration: underline;
}

.banner_area .btns{
    display: flex;
    font-family: 'NoirLight';
    justify-content: center;
}
.banner_area .btns a{
    width: 200px;
    display: inline-block;
    text-align: center;
    height: 51px;
    font-weight: 700;
    line-height: 51px;
    font-size: 18px;
    color: var(--font-color);
    transition: all 0.3s ease-in;
    border-radius: 4px;
    border: 2px solid #895de51f;
    margin: 0 11px;
}
.banner_area .btns a:nth-child(1){
        background: var(--primary-bg);
        color: #fff;
}
.banner_area .btns a i{
    transform: rotate(45deg);
    font-size: 23px;
    font-weight: 600;
    margin: 0 6px;
    color: #4CAF50;
}
.banner_area .btns a:hover{
    transform: translateY(-10px);
}
#banner_slider{}
#banner_slider img{
    width: 100%;
    height: 733px;
    object-fit: cover;
    border-radius: 5px;
}
/* banner_area  */

/* process_area   */
.process_area {
    margin: 6rem 0;
}
.process_area .txt{}
.process_area .txt h2{
    font-family: 'GlancyrBold';
    font-size: 70px;
    color: var(--primary-bg);
}

.process_area .txt h2 span {
    display: block;
}

.process_area .txt h2 span em {color: var(--shade-color);}
.process_area .txt p{
    font-family: 'NoirRegular';
    font-size: 25px;
    width: 57%;
    margin: 26px 0 71px;
}
.process_area .txt .btns{
    font-family: 'NoirRegular';
}
.process_area .txt .btns a{
    width: 200px;
    display: inline-block;
    text-align: center;
    height: 51px;
    font-weight: 700;
    line-height: 51px;
    font-size: 18px;
    color: var(--font-color);
    transition: all 0.3s ease-in;
    border-radius: 4px;
    border: 2px solid #895de51f;
    margin: 0 11px;
}
.process_area .txt .btns a:nth-child(1){
     background: #1A1A1A;
     color: #fff;
}
.process_area .txt .btns a i{
    transform: rotate(45deg);
    font-size: 23px;
    font-weight: 600;
    margin: 0 6px;
    color: #4CAF50;
}
.process_area .txt .btns a:hover{
    transform: translateY(-10px);
}

.process_area .process_box{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 20px;
}
.process_area .process_box .box_1{
    background: #fff;
    width: 100%;
    padding: 4rem 20px;
    border-radius: 9px;
    /* height: 200px; */
    box-shadow: 2px 2px 23px #0000002b;
    transition: all 0.4s ease-in;
    cursor: pointer;
}
.process_area .process_box .box_1:hover{
    background: var(--shade-color);
    color:#fff;
    transform: translateY(-10px);
}
.process_area .process_box .box_1 h4{
    font-family: 'GlancyrBold';
    font-size: 23px;
    text-align: center;
    transition: all 0.4s ease-in;
    color: var(--primary-bg);
}
.process_area .process_box .box_1:hover h4{
    color:#fff;
}
.process_area .process_box .box_1 i{
    text-align: center;
    font-size: 51px;
    margin: 0 auto 23px;
    display: block;
}

/* process_area   */


/* technologies  */

.technologies{
    margin: 7rem 0;
}
.technologies h3{
    font-family: 'GlancyrBold';
    font-size: 61px;
    color: var(--primary-bg);
    text-align: center;
}
.technologies h3 span{
    color: var(--shade-color);
    text-decoration: underline;
}

.technologies .languages{}
.technologies .languages ul{
    display: flex;
    justify-content: center;
    font-family: 'NoirRegular';
    font-size: 20px;
    margin: 3rem 0;
    border: none;
    position: relative;
}
.technologies .languages ul::after{
    content: '';
    position: absolute;
    width: 63%;
    height: 3px;
    background: #887f79f0;
    left: 0;
    bottom: -22px;
    right: 0;
    margin: 0 auto;
}
.technologies .languages ul li{
    margin: 0 26px;
}
.technologies .languages ul li button{
    color: var(--primary-bg);
    border: navajowhite;
    background: transparent;
}
.technologies .languages ul li button.active{
    color: var(--shade-color);
    background: transparent;
}
.technologies .languages .tab-content{
    display: flex;
    justify-content: center;
    margin: 5rem 0 0;
}
.technologies .languages .tab-content img{
    width: 133px;
    object-fit: none;
    margin: 0 2rem;
    height: 104px;
}
/* technologies  */
/* cta_banner  */


.cta_banner{
    background: url('../src/assets//images/cta-banner.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    overflow: hidden;
    padding: 5rem 0;
    margin: 2rem 0;
}

.cta_banner .cta_content{}
.cta_banner .cta_content h2{
    font-family: 'GlancyrBold';
    font-size: 70px;
    line-height: 77px;
    color: #fff;
}
.cta_banner .cta_content h2 span{
    color: var(--shade-color);
    font-size: 72px;
    font-family: 'GlancyrBold';
}
.cta_banner .cta_content span{
    color: #ffff;
    font-family: 'NoirRegular';
    margin: 0 0 12px 0;
    display: block;
    font-size: 22px;
}
.cta_banner .cta_content p{
    font-family: 'NoirRegular';
    font-size: 20px;
    margin: 25px 0 40px;
    line-height: 35px;
    color: #fff;
}
.cta_banner .cta_content .btns{
    font-family: 'NoirRegular';
}
.cta_banner .cta_content .btns a{
    width: 200px;
    display: inline-block;
    text-align: center;
    height: 51px;
    font-weight: 700;
    line-height: 51px;
    font-size: 18px;
    color: #fff;
    transition: all 0.3s ease-in;
    border-radius: 4px;
    border: 2px solid var(--shade-color);
    margin: 0 11px;
}
.cta_banner .cta_content .btns a:nth-child(1){
      background: var(--shade-color);
      color: #fff;
}
.cta_banner .cta_content .btns a:hover{
    transform: translateY(-10px);

}
.cta_banner .cta_content .btns a i{
        transform: rotate(45deg);
    font-size: 23px;
    font-weight: 600;
    margin: 0 6px;
    color: #4CAF50;
}
.cta_banner .cta_img{
    position: relative;
}
.cta_banner .cta_img img{width: 58%;margin: 0 auto;display: block;position: absolute;}
/* cta_banner */


/* services  */
  .services{
    padding: 3rem 0;
}
  .services .head_txt{
    text-align: center;
}
  .services .head_txt h3{
    font-family: 'GlancyrBold';
    font-size: 61px;
    color: var(--primary-bg);
    text-align: center;
}
  .services .head_txt h3 span{
    color: var(--shade-color);
  }
  .services .head_txt p{
    font-family: 'NoirRegular';
    font-size: 25px;
    margin: 11px 0 3rem;
    text-align: center;
}
  .services #services_card{}
  .services #services_card .crd{
    background: #dddced78;
    padding: 21px;
    border-radius: 10px;
    transition: all 0.3s ease-in;
    height: 350px;
}
  .services #services_card .crd:hover{
    transform: translateY(-10px);
    background: #2d52f91f;
  }
  .services #services_card .crd i{
    font-size: 53px;
}
  .services #services_card .crd h4{
    font-family: 'GlancyrBold';
    font-size: 24px;
    margin: 20px 0;
}
  .services #services_card .crd p{
    font-family: 'NoirLight';
    font-size: 16px;
}
  .services #services_card .crd a{
    display: inline-block;
    text-align: center;
    height: 51px;
    font-weight: 700;
    line-height: 51px;
    font-size: 20px;
    color: var(--font-color);
    transition: all 0.3s ease-in;
    border-radius: 4px;
    font-family: 'GlancyrBold';
    cursor: pointer;
}
  .services #services_card .crd a i{
    transform: rotate(45deg);
    margin: 0 10px;
    font-size: 26px;
    color: var(--shade-color);
    font-weight: 600;
}
  .services #services_card .crd a:hover{}
/* services  */

/* awards  */
.awards{
    background: linear-gradient(45deg, #f8f9ff, #f3f4f9);
    padding: 5rem 0;
}
.awards .txt{}
.awards .txt h5 {
    font-family: 'GlancyrBold';
    font-size: 61px;
    color: var(--primary-bg);
}
.awards .txt h5 span{
    color: var(--shade-color);
}
.awards .txt p{
    font-family: 'NoirLight';
    font-size: 19px;
    margin: 22px 0;
    width: 80%;
}
.awards .txt a{
    display: inline-block;
    text-align: center;
    height: 51px;
    font-weight: 700;
    line-height: 51px;
    font-size: 20px;
    color: var(--shade-color);
    transition: all 0.3s ease-in;
    border-radius: 4px;
    font-family: 'GlancyrBold';
}
.awards .txt a i{
    transform: rotate(45deg);
    margin: 0 10px;
    font-size: 26px;
    color: var(--font-color) !important;
    font-weight: 600;
}
.awards .txt a:hover{}
.awards .rating{}
.awards .rating h5{
    font-family: 'GlancyrBold';
    font-size: 30px;
    color: var(--primary-bg);
    display: flex;
}
.awards .rating h5 ul{
    display: flex;
    align-items: center;
    margin: 0 20px;
}
.awards .rating h5 ul li{
    margin: 0 3px;
}
.awards .rating h5 ul li i{
    font-size: 20px;
    color: #ffc520;
}
.awards .rating h5 i{}
.awards .rating h4{
    font-family: 'GlancyrBold';
    font-size: 59px;
    color: var(--primary-bg);
}
.awards .rating h4 span{
    color: var(--shade-color);
    font-size: 34px;
}
.awards #slider_award{}
.awards #slider_award img{
    padding: 20px;
    width: 189px;
    margin: 4rem auto 0;
    display: block;
    background: #e2e6f6;
    height: 189px;
    object-fit: cover;
    border-radius: 5px;
}
/* awards  */

/* cta  */
.cta{
    position: relative;
    text-align: center;
    background: url('https://wallpaper-mania.com/wp-content/uploads/2018/09/High_resolution_wallpaper_background_ID_77701653421.jpg');
    padding: 4rem 0;
    background-repeat: no-repeat;
    overflow: hidden;
}
.cta::after{
    position: absolute;
    content: '';
    background: url('../src/assets/images/bg_icons.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    top: 9%;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(7497%) hue-rotate(138deg) brightness(105%) contrast(101%);
    opacity: 0.3;
}
.cta h6{
    font-family: 'GlancyrBold';
    font-size: 53px;
    color: #fff;
    position: relative;
    line-height: 79px;
    z-index: 2;
}

.cta h6 span {
    display: block;
}
.cta a{
    display: inline-block;
    text-align: center;
    height: 60px;
    font-weight: 700;
    line-height: 60px;
    font-size: 24px;
    color: #fff;
    transition: all 0.3s ease-in;
    border-radius: 4px;
    font-family: 'GlancyrBold';
    background: var(--shade-color);
    width: 18%;
    margin: 2rem 0 0;
}
.cta a i{
     transform: rotate(45deg);
     margin: 0 7px;
     font-size: 26px;
     color: #fff;
     font-weight: 600;
}
.cta a:hover{
    background: #FF5722;
}
/* cta  */


/* crowned  */
.crowned{
    margin: 0 0  0;
    padding: 3rem 0  5rem;
}
.crowned .img{}
.crowned .img img{
    width: 73%;
    margin: 0 auto;
    display: block;
}
.crowned .txt {}
.crowned .txt h5{
    color: var(--shade-color);
    font-size: 33px;
    font-family: 'NoirRegular';
}
.crowned .txt h4{
    font-family: 'GlancyrBold';
    font-size: 61px;
    color: var(--primary-bg);
    line-height: 72px;
    margin: 23px 0;
}
.crowned .txt p{
    font-size: 21px;
    font-family: 'NoirLight';
    color: #000;
    line-height: 36px;
}
.crowned .btns{
    font-family: 'NoirRegular';
}
.crowned .btns a{
    width: 200px;
    display: inline-block;
    text-align: center;
    height: 51px;
    font-weight: 700;
    line-height: 51px;
    font-size: 18px;
    color: var(--font-color);
    transition: all 0.3s ease-in;
    border-radius: 4px;
    border: 2px solid #895de51f;
}
.crowned .btns a:nth-child(1){
    background: var(--primary-bg);
    color: #fff;
}
.crowned .btns a i{
    transform: rotate(45deg);
    margin: 0 10px;
    font-size: 26px;
    color: var(--shade-color);
    font-weight: 600;
}
.crowned .btns a:hover{
    transform: translateY(-10px);
}
/* crowned  */
/* reviews */
.review{
    background: #fafcfe;
    padding: 5rem 0;
}
.review h6{
    font-family: 'NoirRegular';
    font-size: 28px;
    color: var(--shade-color);
    text-align: center;
}
.review h4{
    font-family: 'GlancyrBold';
    font-size: 61px;
    color: var(--primary-bg);
    text-align: center;
}
.review h4 span{
    color: var(--shade-color);
}
.review p{}
.review  #review_slider{}
.review  #review_slider .wrap{}
.review  #review_slider .wrap p{
        font-size: 18px;
        font-family: 'NoirLight';
        color: #000;
        line-height: 31px;
        text-align: center;
        width: 77%;
        margin: 15px auto;
}
.review  #review_slider h3{
    font-family: 'GlancyrBold';
    font-size: 37px;
    color: var(--primary-bg);
    text-align: center;
    margin: 3rem 0 15px;
}
.review  #review_slider h5{
    font-family: 'GlancyrBold';
    font-size: 23px;
    color: var(--shade-color);
    text-align: center;
}
.review  #review_slider img{
    width: 258px;
}
.review  #review_slider span{
    font-size: 18px;
    font-family: 'NoirLight';
    color: #000;
    line-height: 31px;
    text-align: center;
}
.review .owl-nav{
    display: flex;
    justify-content: space-between;
    position: relative;
    top: -12rem;
}
.review .owl-nav button{}
.review .owl-nav button span{
    font-size: 56px !important;
    color: #a7a7a78c !important;
}
/* reviews */
/* footer */
footer {
  background: var( --primary-bg);
  color: #fff;
  padding: 4rem 0 1rem;
}

footer .form-sec p,
.overlay h4,
footer .sec-para {
  color: #fff;
  line-height: 36px;
  font-size: 19px;
}


footer .footer-navigation ul {
  padding-left: 0;
  font-family: 'GlancyrLight';
}

footer .footer-navigation li {
  padding-top: 20px;
}

footer .footer-navigation li a {
  font-weight: 400;
  color: #fff;
  display: flex;
  transition: all 0.3s ease-in-out;
  font-size: 24px;
}
footer .footer-navigation li a:hover{
  color: var(--shade-color);
}

footer .footer-navigation li a i {
  padding: 0 10px;
  font-size: 15px;
  color: var(--font-color);
}

footer .policy-links a {
  font-size: 17px;
  color: #fff;
  margin: 0 0 0 22px;
}

footer .footer-bottom {
  border-top: 1px solid var(--shade-color);
  margin: 3rem 0 1rem;
  font-family: 'GlancyrLight';
  object-fit: cover;
}

footer .footer-bottom img {
    width: 284px;
    margin: 0 auto;
    display: block;
}
footer .call_btn{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    font-size: 4rem;
    font-family: 'GlancyrBold';
    color: #fff;
}
footer .call_btn i{
        transform: rotate(45deg);
        margin: 0 20px;
        font-size: 42px;
        color: var(--shade-color);
        font-weight: 600;
}
/* footer */

/* portfolio  */
.about_banner.portfolio .container-fluid{
    background: #1a1a1a;

}
.about_banner.portfolio{
    text-align: center;
        padding: 6rem 0 0;

}
.about_banner.portfolio p{
    width: 100%;
    color: #fff;
}
.about_banner.portfolio .btns{
    justify-content: center;
}

.about_banner.portfolio h1 {
    color: #fff;
}

.about_banner.portfolio .btns a {
    border: 2px solid #fff;
    color: #fff;
}
/* portfolio  */


/* blog  */
.about_banner.blog .container-fluid {
    background: #101115;
    padding: 1rem 0 0;
}
.about_banner.blog .container-fluid {}
.about_banner.blog .container-fluid {}
.about_banner.blog .container-fluid  .txt{
    padding:0 6rem;
}
.about_banner.blog .container-fluid  .txt h1,
.about_banner.blog .container-fluid  .txt p{
    color: #fff;
}
.about_banner.blog .container-fluid  .btns{}
.about_banner.blog .container-fluid  .btns a{ 
    border: 2px solid #fff;
    padding: 0 1px;
    width: 229px;
}
/* blog  */


/* About Page  */


/* privacy  */

.privacy_txt{
    margin: 3rem 0;
}
.privacy_txt p{
    font-family: 'NoirRegular';
    font-size: 19px;
    margin: 35px 0;
    line-height: 28px;
    color: #585858;
}
.privacy_txt h3{
    font-family: 'GlancyrBold';
    margin: 38px 0 0;
}
.privacy_txt h4{
    font-family: 'GlancyrBold';
    font-size: 39px;
    line-height: 51px;
}
.privacy_txt ul{
    margin: 0 25px;
    list-style: circle;
}
.privacy_txt ul li{
    font-family: 'Exo';
    font-weight: 700;
    line-height: 32px;
    font-size: 20px;
    color: #000000;
    margin: 20px 0;
}
/* privacy  */
/* About Page  */
/* @media (min-width: 1200px){
    .cta_banner .cta_content h2 {
    font-size: 58px;
    line-height: 69px;
}
} */
@media only screen and (min-width: 1600px) and (max-width: 1920px) {}

@media only screen and (min-width: 1445px) and (max-width: 1599px) {
    header {
    top: 10%;
}
.process_area .txt p {
    width: 95%;
}
.about_banner.services .txt h1 {
    font-size: 63px;
}
.top_bar ul li a {
    font-size: 13px;
}
.about_banner .txt h1 {
    font-size: 100px;
}
.crowned .img img {
    width: 84%;
}
#staticBackdrop .modal-dialog {
    margin: 64px auto 0;
}
.portfolio .portfolio_grid img {
    height: 697px;
}
footer .footer-navigation li a {
    font-size: 18px;
}
.contact_page .img_area img {
    width: 95%;
}
.mvv_wrap .item {
    height: 371px;
    padding: 2rem;
}
.technologies .languages .tab-content img {
    width: 110px;
    margin: 0 2rem;
    height: 112px;
}
.cta_banner .cta_content h2 {
    font-size: 59px;
    width: 88% !important;
    line-height: 70px;
}
.cta_banner .cta_content h2 span {
    font-size: 62px;
}
.services #services_card .crd {
    height: 414px;
}
.cta h6 {
    font-size: 47px;
    line-height: 68px;
}
.cta a {
    width: 21%;
}
.crowned .txt h4 {
    font-size: 57px;
}
.about_banner.services .txt span {
    width: 52%;
}
.services_form {
    padding: 2rem;
    width: 89%;
}
.services_form h4 {
    font-size: 30px;
    margin-bottom: 20px;
}
.process_area.services #process_slider .item h3 {
    font-size: 25px;
}
}

@media only screen and (min-width: 1366px) and (max-width: 1444px) {

}

@media only screen and (min-width: 1024px) and (max-width: 1399px) {
    .top_bar ul li a {
    font-size: 12px;
}

.contact_page {
    padding: 19rem 0 2rem;
}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    
 .container-fluid {
    padding: 0 2rem;
}

#staticBackdrop .modal-body .modal-body-img2 img {
    width: 100%;
}
.top_bar {
    /* background: linear-gradient(45deg, #2251fc, #955ee2); */
}
header {
    top: 7%;
}
.banner_area .head_txt h1 {
    font-size: 65px;
    line-height: 90px;
}
#banner_slider img {
    height: 400px;
}
.top_bar ul {
    display: block;
}
.process_area .txt h2 {
    font-size: 45px;
}
.process_area .txt p {
    font-size: 19px;
    width: 100%;
}
.process_area .process_box {
    grid-template-columns: repeat(2,1fr);
    gap: 9px;
}
.process_area .process_box .box_1 {
    padding: 2rem 20px;
}
.process_area .txt .btns a {
    margin: 0 0 11px;
}
.technologies h3 {
    font-size: 55px;
}
.technologies .languages ul {
    font-size: 22px;
    margin: 2rem 0;
}
.technologies .languages ul li {
    margin: 0 16px;
}
.technologies .languages .tab-content img {
    width: 137px;
    margin: 0 auto;
    height: 152px;
}
.cta_banner {
    /* background: linear-gradient(45deg, #2251fc, #955ee2); */
}
.cta_banner .cta_content h2 {
    font-size: 54px;
    line-height: 66px;
}
.cta_banner .cta_content h2 span {
    font-size: 65px;
}
.cta_banner .cta_content {
    position: relative;
    z-index: 2;
    padding: 29px;
    background: rgb(128 85 206 / 38%);
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border-radius: 4px;
}
.cta_banner .cta_content h2 {
    font-size: 54px;
    line-height: 66px;
}
.cta_banner .cta_img img {
    position: absolute;
    right: 0;
    top: 21%;
    transform: translateY(-86%);
    width: 48%;
}
.technologies {
    margin: 6rem 0 4rem;
}
.services .head_txt p {
    font-size: 18px;
}
.services #services_card .crd {
    height: 460px;
}
.awards .txt h5 {
    font-size: 63px;
}
.awards .txt p {
    font-size: 18px;
    width: 85%;
    line-height: 34px;
}
.awards .rating h5 {
    font-size: 38px;
}
.awards .rating h4 {
    font-size: 48px;
}
.awards .rating h4 span {
    font-size: 33px;
}
.cta h6 {
    font-size: 59px;
    line-height: 73px;
}
.cta::after {
    top: -3%;
}
.review #review_slider .wrap p {
    font-size: 20px;
    width: 100%;
    margin: 8px auto;
}
footer .call_btn {
    font-size: 3rem;
}
footer .footer-navigation li a {
    font-size: 20px;
}
footer {
    padding: 1rem 0;
}
footer .footer-bottom img {
    width: 245px;
    margin: 0 auto;
}
footer .policy-links a {
    font-size: 14px;
    margin: 0 0 0 22px;
}
.crowned .txt h4 {
    font-size: 40px;
    margin: 23px 0;
    line-height: 51px;
}
.crowned .txt h5 {
    font-size: 24px;
}
.crowned .txt p {
    font-size: 22px;
    line-height: 35px;
}
.crowned .btns a {
    margin: 6px 0;
}
.crowned .img img {
    width: 95%;
    margin: 0 auto;
    margin-top: 0;
}
header.sticky nav ul li a.nav-link {
    color: #000;
}
header nav a img {
    width: 186px;
}
header .call_btns a {
    width: 131px;
    display: unset;
    font-size: 15px;
    padding: 10px;
}
header nav ul li a.nav-link {
    font-size: 14px;
}

.counter .count_data p {
    font-size: 13px;
}
.about_banner .container-fluid {
    padding: 6rem 2rem;
}
.about_banner .txt h1 {
    font-size: 58px;
}
.about_banner .btns a {
    width: 175px;
    height: 33px;
    line-height: 32px;
    font-size: 13px;
}
.about_banner {
    padding: 5rem 0 2rem;
}
.mvv_wrap .item {
    height: 565px;
    padding: 2rem 1rem;
}
.mvv_wrap .item p {
    font-size: 14px;
    margin: 4px 10px;
    width: 100%;
    line-height: 26px;
}
.mvv_wrap .item h6 {
    font-size: 21px;
    margin: 10px 10px;
    width: 100%;
    line-height: 34px;
}
.mvv_wrap .item h3 {
    font-size: 45px;
}
.counter .count_data {
    display: flex;
}
.counter .count_data h4 {
    font-size: 50px;
    line-height: 72px;
    margin: 11px 0;
}
.counter .count_data h4::after {
    right: -15px;
    font-size: 50px;
}
.cta_banner.about {
    padding: 2rem 0 4rem !important;
}
.review {
    padding: 3rem 0 0;
}

.about_banner .txt span {
    font-size: 35px;
}
.about_banner .txt p {
    font-size: 23px;
    margin: 8px 10px;
    line-height: 35px;
    width: 100%;
}
.portfolio .portfolio_grid {
    grid-template-columns: repeat(3,1fr);
}
.portfolio .portfolio_grid img {
    height: 500px;
}
.cta a {
    height: 43px;
    line-height: 43px;
    font-size: 16px;
    width: 57%;
    margin: 1rem 0 0;
}
.portfolio h4 {
    font-size: 48px;
    margin: 0 0 2rem;
}
.process_area.services .txt h2 {
    font-size: 27px !important;
}
.process_area.services {
    margin: 0;
}
.about_banner.services .txt span {
    width: 100%;
    font-size: 16px;
}
.about_banner.services .txt h1 {
    font-size: 29px !important;
    line-height: 41px;
}
.about_banner.services h6 {
    font-size: 16px;
    margin: 36px 20px;
}
.services_form {
    padding: 2rem;
    width: 100%;
}
.services_form h4 {
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 18px;
}
.process_area.services #process_slider .item h3 {
    font-size: 25px;
}
.process_area.services .owl-carousel .owl-nav {
    top: 26px;
    position: relative;
}
.cta_banner.services .cta_content{
    background: none;
}
.cta_banner.services .cta_content h2 {
    font-size: 31px !important;
    line-height: 45px !important;
    width: 100% !important;
}
.cta_banner.services .cta_content p {
    font-size: 14px;
    margin: 8px 0 16px;
    line-height: 22px;
}
.cta_banner.services .cta_img img {
    display: none;
}
.contact_page {
    padding: 6rem 0 4rem;
}
.contact_page .contact_frm h4 {
    font-size: 45px;
    margin: 46px 0 0;
}
.top_bar p {
    margin: 18px 0 0;
}
.about_banner.portfolio {
    padding: 6rem 0 0;
}
.review #review_slider h3 {
    font-size: 19px;
    margin: 1rem 0 7px;
}
.contact_page .contact_frm p {
    margin: 22px 0;
    font-size: 17px;
}





.about_banner.blog .container-fluid .txt {
    padding: 1rem;
}
.blog_card .item h3 {
    font-size: 24px;
}
.blog_card .item p {
    font-size: 14px;
    margin: 7px 3px;
    line-height: 22px;
}
.blog_card .item img {
    height: 224px;
}
.blog_card2 .item img {
    height: 197px;
}
.old_blog .left_txt .crd {
    display: block;
}
.old_blog .left_txt h4 {
    font-size: 38px;
    margin: 0 0 30px 0;
}
.old_blog .left_txt {
    height: 100%;
    overflow: inherit;
}
.old_blog .left_txt .crd img {
    width: 100%;
    height: 194px;
}
.old_blog .right_txt .category_btn {
    grid-template-columns: repeat(2,1fr);
    gap: 10px;
}
.old_blog .right_txt .category_btn a {
    padding: 6px;
    font-size: 15px;
}
.old_blog .frm form input {
    padding: 13px 20px;
    font-size: 16px;
}
.old_blog .frm form button {
    right: -4%;
    top: 31%;
    font-size: 17px;
}
.old_blog .talk {
    display: block;
    padding: 2rem;
     margin: 0 0 3rem 0;
}
.old_blog .talk img{
    display: none;
}
.old_blog .talk .txt h3 {
    font-size: 38px;
}
.old_blog .right_txt h4 {
    font-size: 29px;
    margin: 24px 0;
}
.old_blog .left_txt .crd h5 {
    font-size: 23px;
}
.old_blog .talk .txt a {
    padding: 7px 15px;
    font-size: 14px;
}
.awards.about #slider_award img {
    width: 286px !important;
    height: 255px !important;
}



.modal-body-form h3 {
    font-size: 36px;
    margin: 10px 0 0 0;
}
.modal-body-form p {
    margin: 7px 0 15px;
    font-size: 12px;
}
.modal-body-form .popup-form-list .form-group input {
    font-size: 12px;
    padding: 11px 15px;
    margin: 2px 0;
}
.modal-body-form .popup-form-list .form-group input::placeholder,
.modal-body-form .popup-form-list .form-group textarea::placeholder{

    font-size: 12px;
}
.modal-body-form .popup-form-list .form-group button.submit-btn {
    font-size: 13px;
    width: 103px;
    padding: 4px 10px;
}
#staticBackdrop .modal-dialog {
    max-width: 837px !important;
}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .container-fluid {
    padding: 0 2rem;
}
.modal-body-form h3 {
    font-size: 36px;
    margin: 10px 0 0 0;
}
.modal-body-form p {
    margin: 7px 0 7px;
    font-size: 12px;
}
.modal-body-form .popup-form-list .form-group input {
    font-size: 12px;
    padding: 11px 15px;
    margin: 2px 0;
}
.modal-body-form .popup-form-list .form-group input::placeholder,
.modal-body-form .popup-form-list .form-group textarea::placeholder{

    font-size: 12px;
}
.modal-body-form .popup-form-list .form-group button.submit-btn {
    font-size: 13px;
    width: 103px;
    padding: 4px 10px;
}
.modal-body-form .popup-form-list .form-group textarea {
    font-size: 13px;
    width: 100%;
    height: 79px;
    padding: 10px 15px;
    margin: 9px 0 0;
}
    .banner_area .head_txt h1 {
    font-size: 54px;
    line-height: 68px;
}
.navbar-toggler {
    background: linear-gradient(45deg, #2251fc, #955ee2);
    padding: 9px 18px;
}
.navbar-toggler i{
    color: #fff;
    font-size: 27px;
}
.navbar-nav {
    background: #1a1a1a;
    padding: 22px;
    border-radius: 20px 20px 0 0;
}
.navbar-toggler:focus {
    box-shadow: none;
}
header nav ul li a.nav-link {
    color: #fff;
}
header .call_btns {
    display: flex;
    justify-content: center;
    background: linear-gradient(45deg, #2251fc, #955ee2);
    border-radius: 0 0 20px 20px;
    padding: 17px 0;
}
header .call_btns a {
    color: #fff;
    border: 2px solid #ffffff;
    margin: 0 11px;
}
.nav-link:focus, .nav-link:hover {
    color: #fff;
}
#banner_slider img {
    height: 509px;
}
.top_bar ul {
    justify-content: center;
}
.process_area .txt h2 {
    font-size: 31px;
}
.process_area .txt p {
    font-size: 19px;
    width: 100%;
    margin: 34px 0;
}
.process_area .process_box {
    grid-template-columns: repeat(2,1fr);
    gap: 9px;
}
.process_area .process_box .box_1 {
    padding: 2rem 20px;
}
.process_area .txt .btns a {
    margin: 0 0 11px;
}
.technologies h3 {
    font-size: 41px;
}
.technologies .languages ul {
    font-size: 16px;
    margin: 2rem 0;
}
.technologies .languages ul li {
    margin: 0 8px;
}
.technologies .languages .tab-content img {
    width: 137px;
    margin: 0 auto;
    height: 152px;
}
.cta_banner {
    /* background: linear-gradient(45deg, #2251fc, #955ee2); */
}
.cta_banner .cta_content h2 {
    font-size: 54px;
    line-height: 66px;
}
.cta_banner .cta_content h2 span {
    font-size: 65px;
}
.cta_banner .cta_content {
    position: relative;
    z-index: 2;
    padding: 29px;
    background: rgb(255 255 255 / 20%);
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border-radius: 4px;
}
.cta_banner .cta_content h2 {
    font-size: 54px;
    line-height: 66px;
}
.cta_banner .cta_img img {
    position: absolute;
    right: 0;
    top: 21%;
    transform: translateY(-86%);
}
.technologies {
    margin: 6rem 0 4rem;
}
.services .head_txt p {
    font-size: 18px;
}
.services #services_card .crd {
    height: 460px;
}
.awards .txt h5 {
    font-size: 48px;
}
.awards .txt p {
    font-size: 18px;
    width: 85%;
    line-height: 34px;
}
.awards .rating h5 {
    font-size: 18px;
}
.awards .rating h4 {
    font-size: 41px;
}
.awards .rating h4 span {
    font-size: 20px;
}
.cta h6 {
    font-size: 46px;
    line-height: 70px;
}
.cta::after {
    top: -3%;
}
.review #review_slider .wrap p {
    font-size: 18px;
    width: 100%;
    margin: 7px auto;
}
footer .call_btn {
    font-size: 3rem;
}
footer .footer-navigation li a {
    font-size: 14px;
}
footer {
    padding: 1rem 0;
}
footer .footer-bottom img {
    width: 207px;
    margin: 0 auto;
}
footer .policy-links a {
    font-size: 10px;
    margin: 0 0 0 22px;
}
.crowned .txt h4 {
    font-size: 34px;
    line-height: 44px;
    margin: 23px 0;
}
.crowned .txt h5 {
    font-size: 24px;
}
.crowned .txt p {
    font-size: 18px;
    line-height: 32px;
}
.crowned .btns a {
    margin: 6px 0;
}
.crowned .img img {
    width: 62%;
    margin: 0 auto;
}
header.sticky nav ul li a.nav-link {
    color: #fff;
}
#staticBackdrop .modal-dialog {
    max-width: 535px !important;
}
header {
    top: 11%;
}

.counter .count_data p {
    font-size: 13px;
}
.about_banner .container-fluid {
    padding: 6rem 2rem;
}
.about_banner .txt h1 {
    font-size: 50px;
}
.about_banner .btns a {
    width: 175px;
    height: 33px;
    line-height: 32px;
    font-size: 13px;
}
.about_banner {
    padding: 5rem 0 2rem;
}
.mvv_wrap .item {
    height: 100%;
    padding: 2rem 1rem;
   
}
.mvv_wrap .item p {
    font-size: 14px;
    margin: 4px 10px;
    width: 100%;
    line-height: 26px;
}
.mvv_wrap .item h6 {
    font-size: 23px;
    margin: 10px 10px;
    width: 100%;
    line-height: 38px;
}
.mvv_wrap .item h3 {
    font-size: 45px;
}
.counter .count_data {
    display: flex;
}
.counter .count_data h4 {
    font-size: 50px;
    line-height: 72px;
    margin: 11px 0;
}
.counter .count_data h4::after {
    right: 59px;
    display: none;
    font-size: 50px;
}
.cta_banner.about {
    padding: 2rem 0 4rem !important;
}
.review {
    padding: 3rem 0 0;
}
.about_banner .txt span {
    font-size: 35px;
}
.about_banner .txt p {
    font-size: 23px;
    margin: 8px 10px;
    line-height: 35px;
    width: 100%;
}
.portfolio .portfolio_grid {
    grid-template-columns: repeat(2,1fr);
}
.portfolio .portfolio_grid img {
    height: 100%;
}
.cta a {
    height: 43px;
    line-height: 43px;
    font-size: 16px;
    width: 57%;
    margin: 1rem 0 0;
}
.portfolio h4 {
    font-size: 48px;
    margin: 0 0 2rem;
}
.process_area.services .txt h2 {
    font-size: 27px !important;
}
.process_area.services {
    margin: 0;
}
.about_banner.services .txt span {
    width: 100%;
    font-size: 16px;
}
.about_banner.services .txt h1 {
    font-size: 29px !important;
    line-height: 41px;
}
.about_banner.services h6 {
    font-size: 16px;
    margin: 36px 20px;
}
.services_form {
    padding: 2rem;
    width: 100%;
}
.services_form h4 {
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 18px;
}
.process_area.services #process_slider .item h3 {
    font-size: 25px;
}
.process_area.services .owl-carousel .owl-nav {
    top: 26px;
    position: relative;
}
.cta_banner.services .cta_content{
    background: none;
}
.cta_banner.services .cta_content h2 {
    font-size: 31px !important;
    line-height: 45px !important;
    width: 100% !important;
}
.cta_banner.services .cta_content p {
    font-size: 14px;
    margin: 8px 0 16px;
    line-height: 22px;
}
.cta_banner.services .cta_img img {
    display: none;
}
.contact_page {
    padding: 6rem 0 4rem;
}
.contact_page .contact_frm h4 {
    font-size: 45px;
    margin: 46px 0 0;
}
.top_bar p {
    margin: 18px 0 0;
}
.about_banner.portfolio {
    padding: 6rem 0 0;
}
.review #review_slider h3 {
    font-size: 19px;
    margin: 1rem 0 7px;
}
.contact_page .contact_frm p {
    margin: 22px 0;
    font-size: 17px;
}





.about_banner.blog .container-fluid .txt {
    padding: 1rem;
}
.blog_card .item h3 {
    font-size: 24px;
}
.blog_card .item p {
    font-size: 14px;
    margin: 7px 3px;
    line-height: 22px;
}
.blog_card .item img {
    height: 224px;
}
.blog_card2 .item img {
    height: 278px;
}
.old_blog .left_txt .crd {
    display: block;
}
.old_blog .left_txt h4 {
    font-size: 38px;
    margin: 0 0 30px 0;
}
.old_blog .left_txt {
    height: 100%;
    overflow: inherit;
}
.old_blog .left_txt .crd img {
    width: 100%;
    height: 194px;
}
.old_blog .right_txt .category_btn {
    grid-template-columns: repeat(2,1fr);
    gap: 10px;
}
.old_blog .right_txt .category_btn a {
    padding: 6px;
    font-size: 15px;
}
.old_blog .frm form input {
    padding: 13px 20px;
    font-size: 16px;
}
.old_blog .frm form button {
    right: -4%;
    top: 31%;
    font-size: 17px;
}
.old_blog .talk {
    display: block;
    padding: 2rem;
     margin: 0 0 3rem 0;
}
.old_blog .talk img{
    display: none;
}
.old_blog .talk .txt h3 {
    font-size: 38px;
}
.old_blog .right_txt h4 {
    font-size: 29px;
    margin: 24px 0;
}
.old_blog .left_txt .crd h5 {
    font-size: 23px;
}
.old_blog .talk .txt a {
    padding: 7px 15px;
    font-size: 14px;
}

}

@media only screen and (min-width: 520px) and (max-width: 767px) {
        body {
    background-position: 62% -28%;
}
     .container-fluid {
    padding: 0 2rem;
}
header{
    top: 20%;
}

    .banner_area .head_txt h1 {
    font-size: 46px;
    line-height: 57px;
    padding: 0 27px;
    margin: 5rem 0 2rem;
}
.navbar-toggler {
    background: linear-gradient(45deg, #2251fc, #955ee2);
    padding: 11px 20px;
}
.navbar-toggler i{
    color: #fff;
    font-size: 26px;
}
.navbar-nav {
    background: #1a1a1a;
    padding: 22px;
    border-radius: 20px 20px 0 0;
    width: 100%;
}
.navbar-toggler:focus {
    box-shadow: none;
}
header nav ul li a.nav-link {
    color: #fff;
}
header .call_btns {
    display: flex;
    justify-content: center;
    background: linear-gradient(45deg, #2251fc, #955ee2);
    border-radius: 0 0 20px 20px;
    padding: 17px 0;
}
header .call_btns a {
    color: #fff;
    border: 2px solid #ffffff;
    margin: 0 11px;
}
.nav-link:focus, .nav-link:hover {
    color: #fff;
}
#banner_slider img {
    height: 437px;
    /* padding: 0 6px; */
}
.top_bar ul {
    justify-content: center;
}
.process_area .txt h2 {
    font-size: 54px;
}
.process_area .txt p {
    font-size: 19px;
    width: 100%;
}
.process_area .process_box {
    grid-template-columns: repeat(2,1fr);
    gap: 9px;
}
.process_area .process_box .box_1 {
    padding: 3rem 20px;
}
.process_area .txt .btns a {
    margin: 0 0 11px;
}
.technologies h3 {
    font-size: 35px;
}
.technologies .languages ul {
    font-size: 16px;
    margin: 2rem 0;
}
.technologies .languages ul li {
    margin: 0 16px;
}
.technologies .languages .tab-content img {
    width: 137px;
    margin: 0 auto;
    height: 152px;
}
.cta_banner {
    /* background: linear-gradient(45deg, #2251fc, #955ee2); */
    padding: 2rem 0;
}
.cta_banner .cta_content h2 {
    font-size: 54px;
    line-height: 66px;
}
.cta_banner .cta_content h2 span {
    font-size: 28px;
}
.cta_banner .cta_content {
    position: relative;
    z-index: 2;
    padding: 29px;
    background: #000000d6;
    border-radius: 9px;
}
.cta_banner .cta_content h2 {
    font-size: 27px;
    line-height: 36px;
}
.cta_banner .cta_img img {
    position: absolute;
    right: 0;
    top: 32%;
    transform: translateY(-72%);
}
.technologies {
    margin: 6rem 0 4rem;
}
.services .head_txt p {
    font-size: 16px;
}
.services #services_card .crd {
    height: 456px;
}
.awards .txt h5 {
    font-size: 48px;
}
.awards .txt p {
    font-size: 16px;
    width: 100%;
    line-height: 34px;
}
.awards .rating h5 {
    font-size: 23px;
}
.awards .rating h4 {
    font-size: 48px;
}
.awards .rating h4 span {
    font-size: 20px;
}
.cta h6 {
    font-size: 47px;
    line-height: 60px;
}
.cta::after {
    top: -3%;
}
.review #review_slider .wrap p {
    font-size: 16px;
    width: 100%;
    margin: 5px auto;
}
footer .call_btn {
    font-size: 2rem;
    padding: 1rem 0 2rem;
}
footer .footer-navigation li a {
    font-size: 18px;
    /* justify-content: center; */
}
footer {
    padding: 2rem 0 1rem;
}
footer .footer-bottom img {
    width: 248px;
    margin: 0 auto;
}
footer .policy-links a {
    font-size: 15px;
    margin: 25px 6px 0;
    display: inline-block;
}
.crowned .txt h4 {
    font-size: 38px;
    line-height: 54px;
    margin: 23px 0;
}
.crowned .txt h5 {
    font-size: 24px;
}
.crowned .txt p {
    font-size: 16px;
    line-height: 30px;
}
.crowned .btns a {
    margin: 6px 0;
}
.crowned .img img {
    width: 60%;
    margin: 0 auto;
}
header.sticky nav ul li a.nav-link {
    color: #fff;
}
.top_bar {
    padding: 15px 0;
}
.top_bar ul li a {
    font-size: 14px;
}
header nav a img {
    width: 220px;
}
.cta {
    padding: 2rem 0;
}
.floatbutton{
    /* display: none; */
}
.cta_banner .container-fluid{
    padding: 0 1rem;
}
.cta_banner .cta_content p {
    font-size: 17px;
    margin: 15px 0 21px;
    line-height: 30px;
}
.cta_banner .cta_content span {
    margin: 0 0 9px 0;
    font-size: 16px;
}
.cta_banner .cta_content .btns a {
  margin: 2px 0;
}
.services .head_txt h3 {
    font-size: 45px;
}
.review h4 {
    font-size: 49px;
}
.review h6 {
    font-size: 24px;
}

.review #review_slider img {
    width: 190px;
}
.review #review_slider span {
    font-size: 11px;
    line-height: 17px;
}
.banner_area .btn a {
    width: 164px;
    margin: 0 2px;
}
footer .footer-bottom{
    text-align: center;
}
.top_bar ul li {
    margin: 0 26px 0 0;
}
.theme-btn {
    height: 49px;
    min-width: 49px;
    font-size: 26px;
        bottom: 85px;

}



.about_banner .container-fluid {
    padding: 1rem;
}
.about_banner .txt h1 {
    font-size: 49px;
}
.about_banner .btns a {
    width: 175px;
    height: 33px;
    line-height: 32px;
    font-size: 13px;
}
.about_banner {
    padding: 5rem 0 2rem;
}
.mvv_wrap .item {
    height: 100%;
    padding: 2rem 1rem;
   
}
.mvv_wrap .item p {
    font-size: 13px;
    margin: 4px 10px;
    width: 100%;
    line-height: 19px;
}
.mvv_wrap .item h6 {
    font-size: 20px;
    margin: 10px 10px;
    width: 100%;
    line-height: 23px;
}
.mvv_wrap .item h3 {
    font-size: 42px;
}
.counter .count_data {
    display: block;
}
.counter .count_data h4 {
    font-size: 61px;
    line-height: 72px;
    margin: 11px 0;
}
.counter .count_data h4::after {
    right: 63px;
    font-size: 63px;
}
.cta_banner.about {
    padding: 2rem 0 4rem !important;
}
.review {
    padding: 3rem 0 0;
}
.about_banner .txt span {
    font-size: 23px;
}
.about_banner .txt p {
    font-size: 16px;
    margin: 8px 10px;
}
.portfolio .portfolio_grid {
    grid-template-columns: repeat(2,1fr);
}
.portfolio .portfolio_grid img {
    height: 100%;
}
.cta a {
    height: 43px;
    line-height: 43px;
    font-size: 16px;
    width: 57%;
    margin: 1rem 0 0;
}
.portfolio h4 {
    font-size: 48px;
    margin: 0 0 2rem;
}
.process_area.services .txt h2 {
    font-size: 27px !important;
}
.process_area.services {
    margin: 0;
}
.about_banner.services .txt span {
    width: 100%;
    font-size: 16px;
}
.about_banner.services .txt h1 {
    font-size: 29px !important;
    line-height: 41px;
}
.about_banner.services h6 {
    font-size: 16px;
    margin: 36px 20px;
}
.services_form {
    padding: 2rem;
    width: 100%;
}
.services_form h4 {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 18px;
}
.process_area.services #process_slider .item h3 {
    font-size: 25px;
}
.process_area.services .owl-carousel .owl-nav {
    top: 26px;
    position: relative;
}
.cta_banner.services .cta_content{
    background: none;
}
.cta_banner.services .cta_content h2 {
    font-size: 31px !important;
    line-height: 45px !important;
    width: 100% !important;
}
.cta_banner.services .cta_content p {
    font-size: 14px;
    margin: 8px 0 16px;
    line-height: 22px;
}
.cta_banner.services .cta_img img {
    display: none;
}
.contact_page {
    padding: 6rem 0 4rem;
}
.contact_page .contact_frm h4 {
    font-size: 45px;
    margin: 46px 0 0;
}
.top_bar p {
    margin: 18px 0 0;
}
.about_banner.portfolio {
    padding: 4rem 0 0;
}
.about_banner.blog .container-fluid .txt {
    padding: 1rem;
}
.blog_card .item h3 {
    font-size: 24px;
}
.blog_card .item p {
    font-size: 14px;
    margin: 7px 3px;
    line-height: 22px;
}
.blog_card .item img {
    height: 224px;
}
.blog_card2 .item img {
    height: 278px;
}
.old_blog .left_txt .crd {
    display: block;
}
.old_blog .left_txt h4 {
    font-size: 38px;
    margin: 0 0 30px 0;
}
.old_blog .left_txt {
    height: 100%;
    overflow: inherit;
}
.old_blog .left_txt .crd img {
    width: 100%;
    height: 194px;
}
.old_blog .right_txt .category_btn {
    grid-template-columns: repeat(2,1fr);
    gap: 10px;
}
.old_blog .right_txt .category_btn a {
    padding: 6px;
    font-size: 15px;
}
.old_blog .frm form input {
    padding: 13px 20px;
    font-size: 16px;
}
.old_blog .frm form button {
    right: -4%;
    top: 31%;
    font-size: 17px;
}
.old_blog .talk {
    display: block;
    padding: 2rem;
     margin: 0 0 3rem 0;
}
.old_blog .talk img{
    display: none;
}
.old_blog .talk .txt h3 {
    font-size: 38px;
}
.old_blog .right_txt h4 {
    font-size: 29px;
    margin: 24px 0;
}
.old_blog .left_txt .crd h5 {
    font-size: 23px;
}
.old_blog .talk .txt a {
    padding: 7px 15px;
    font-size: 14px;
}

.modal-body-form p {
    margin: 18px 0;
    font-size: 11px;
}
.modal-body-form .popup-form-list .form-group input::placeholder,
.modal-body-form .popup-form-list .form-group textarea::placeholder{
    font-size: 12px;

}
.modal-body-form .popup-form-list .form-group input {
    font-size: 12px;
    padding: 12px 15px;
    margin: 4px 0;
}
.modal-body-form .popup-form-list .form-group button.submit-btn {
    font-size: 14px;
    width: 100%;
    padding: 6px 10px;
    margin: 0px 0 19px;
}

.modal-body-form .popup-form-list .form-group label {
    position: absolute;
    left: 17px;
    top: -5px;
    padding: 3px;
    font-size: 10px;
}
.banner_area .btn a i {
    display: none;
}
}

@media only screen and (min-width: 300px) and (max-width: 519px) {
    
    body {
    background-position: 62% -28%;
}
.banner_area .btn a i {
    display: none;
}
     .container-fluid {
    padding: 0 2rem;
}


    .banner_area .head_txt h1 {
    font-size: 35px;
    line-height: 46px;
    padding: 0 27px;
    margin: 1rem 0 1rem;
}
.navbar-toggler {
    background: linear-gradient(45deg, #2251fc, #955ee2);
    padding: 5px 11px;
}
.navbar-toggler i{
    color: #fff;
    font-size: 21px;
}
.navbar-nav {
    background: #1a1a1a;
    padding: 22px;
    border-radius: 20px 20px 0 0;
    width: 100%;
}
.navbar-toggler:focus {
    box-shadow: none;
}
header nav ul li a.nav-link {
    color: #fff;
}
header .call_btns {
    display: flex;
    justify-content: center;
    background: linear-gradient(45deg, #2251fc, #955ee2);
    border-radius: 0 0 20px 20px;
    padding: 17px 0;
    width: 100%;
    position: relative;
    /* left: 5px; */
}
header .call_btns a {
    color: #fff;
    border: 2px solid #ffffff;
    margin: 0 11px;
    font-size: 14px;
    width: 100%;
}
.nav-link:focus, .nav-link:hover {
    color: #fff;
}
#banner_slider img {
    height: 440px;
    padding: 0 6px;
}
.top_bar ul {
    justify-content: center;
}
.process_area .txt h2 {
    font-size: 40px;
}
.process_area .txt p {
    font-size: 19px;
    width: 100%;
    margin: 31px 0 32px;
}
.process_area .process_box {
    grid-template-columns: repeat(1,1fr);
    gap: 9px;
}
.process_area .process_box .box_1 {
    padding: 3rem 20px;
}
.process_area .txt .btns a {
    margin: 0 0 11px;
}
.technologies h3 {
    font-size: 30px;
}
.technologies .languages ul {
    font-size: 16px;
    margin: 2rem 0;
}
.technologies .languages ul li {
    margin: 0 39px;
}
.technologies .languages .tab-content img {
    width: 137px;
    margin: 0 auto;
    height: 152px;
}
.cta_banner {
    /* background: linear-gradient(45deg, #2251fc, #955ee2); */
    padding: 2rem 0;
}
.cta_banner .cta_content h2 {
    font-size: 54px;
    line-height: 66px;
}
.cta_banner .cta_content h2 span {
    font-size: 24px;
}
.cta_banner .cta_content {
    position: relative;
    z-index: 2;
    padding: 29px;
    background: #000000d6;
    border-radius: 9px;
}
.cta_banner .cta_content h2 {
    font-size: 24px;
    line-height: 36px;
}
.cta_banner .cta_img img {
    position: absolute;
    right: 0;
    top: 32%;
    transform: translateY(-72%);
}
.technologies {
    margin: 6rem 0 4rem;
}
.services .head_txt p {
    font-size: 16px;
}
.services #services_card .crd {
    height: 460px;
}
.awards .txt h5 {
    font-size: 48px;
}
.awards .txt p {
    font-size: 16px;
    width: 100%;
    line-height: 34px;
}
.awards .rating h5 {
    font-size: 18px;
}
.awards .rating h4 {
    font-size: 41px;
}
.awards .rating h4 span {
    font-size: 20px;
}
.cta h6 {
    font-size: 20px;
    line-height: 31px;
}
.cta::after {
    top: -3%;
}
.review #review_slider .wrap p {
    font-size: 14px;
    width: 100%;
    margin: 5px auto;
}
footer .call_btn {
    font-size: 2rem;
}
footer .footer-navigation li a {
    font-size: 18px;
    /* justify-content: center; */
}
footer {
    padding: 2rem 0;
}
footer .footer-bottom img {
    width: 207px;
    margin: 0 auto;
}
footer .policy-links a {
    font-size: 15px;
    margin: 25px 6px 0;
    display: inline-block;
}
.crowned .txt h4 {
    font-size: 30px;
    line-height: 43px;
    margin: 23px 0;
}
.crowned .txt h5 {
    font-size: 24px;
}
.crowned .txt p {
    font-size: 16px;
    line-height: 30px;
}
.crowned .btns a {
    margin: 6px 0;
}
.crowned .img img {
    width: 95%;
    margin: 0 auto;
}
header.sticky nav ul li a.nav-link {
    color: #fff;
}
.top_bar {
    padding: 17px 0;
}
.top_bar ul li a {
    font-size: 9px;
}
header nav a img {
    width: 182px;
}
.cta {
    padding: 2rem 0;
}
.floatbutton{
    display: none;
}
.cta_banner .container-fluid{
    padding: 0 1rem;
}
.cta_banner .cta_content p {
    font-size: 17px;
    margin: 15px 0 21px;
    line-height: 30px;
}
.cta_banner .cta_content span {
    margin: 0 0 9px 0;
    font-size: 16px;
}
.cta_banner .cta_content .btns a {
  margin: 8px 0 !important;
}
.services .head_txt h3 {
    font-size: 34px;
}
.review h4 {
    font-size: 31px;
}
.review h6 {
    font-size: 18px;
}

.review #review_slider img {
    width: 190px;
}
.review #review_slider span {
    font-size: 11px;
    line-height: 17px;
}
.banner_area .btn a {
    width: 164px;
    margin: 0 2px;
}
header {
    top: 20%;
}
.top_bar ul li {
    margin: 0 26px 0 0;
}

.theme-btn {
    height: 49px;
    min-width: 49px;
    font-size: 26px;
        bottom: 85px;

}
.modal-body-form h3 {
    font-size: 28px;
    line-height: 39px;
    margin: 24px 0 -8px;
}
#staticBackdrop .modal-body .modal-body-img2 img{
    display: none;
}
#staticBackdrop .modal-dialog {
    margin: 6rem 1rem 0;
}


.about_banner .container-fluid {
    padding: 1rem;
}
.about_banner .txt h1 {
    font-size: 49px;
}
.about_banner .btns a {
    width: 175px;
    height: 33px;
    line-height: 32px;
    font-size: 13px;
}
.about_banner {
    padding: 5rem 0 2rem;
}
.mvv_wrap .item {
    height: 100%;
    padding: 2rem 1rem;
   
}
.mvv_wrap .item p {
    font-size: 13px;
    margin: 4px 10px;
    width: 100%;
    line-height: 19px;
}
.mvv_wrap .item h6 {
    font-size: 20px;
    margin: 10px 10px;
    width: 100%;
    line-height: 23px;
}
.mvv_wrap .item h3 {
    font-size: 42px;
}
.counter .count_data {
    display: block;
}
.counter .count_data h4 {
    font-size: 61px;
    line-height: 72px;
    margin: 11px 0;
}
.counter .count_data h4::after {
    right: 63px;
    font-size: 63px;
}
.cta_banner.about {
    padding: 2rem 0 4rem !important;
}
.review {
    padding: 3rem 0 0;
}
.about_banner .txt span {
    font-size: 23px;
}
.about_banner .txt p {
    font-size: 16px;
    margin: 8px 10px;
}
.portfolio .portfolio_grid {
    grid-template-columns: repeat(2,1fr);
}
.portfolio .portfolio_grid img {
    height: 100%;
}
.cta a {
    height: 43px;
    line-height: 43px;
    font-size: 16px;
    width: 57%;
    margin: 1rem 0 0;
}
.portfolio h4 {
    font-size: 48px;
    margin: 0 0 2rem;
}
.process_area.services .txt h2 {
    font-size: 27px !important;
}
.process_area.services {
    margin: 0;
}
.about_banner.services .txt span {
    width: 100%;
    font-size: 16px;
}
.about_banner.services .txt h1 {
    font-size: 29px !important;
    line-height: 41px;
}
.about_banner.services h6 {
    font-size: 16px;
    margin: 36px 20px;
}
.services_form {
    padding: 2rem;
    width: 100%;
}
.services_form h4 {
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 18px;
}
.process_area.services #process_slider .item h3 {
    font-size: 25px;
}
.process_area.services .owl-carousel .owl-nav {
    top: 26px;
    position: relative;
}
.cta_banner.services .cta_content{
    background: none;
}
.cta_banner.services .cta_content h2 {
    font-size: 31px !important;
    line-height: 45px !important;
    width: 100% !important;
}
.cta_banner.services .cta_content p {
    font-size: 14px;
    margin: 8px 0 16px;
    line-height: 22px;
}
.cta_banner.services .cta_img img {
    display: none;
}
.contact_page {
    padding: 6rem 0 4rem;
}
.contact_page .contact_frm h4 {
    font-size: 45px;
    margin: 46px 0 0;
}
.top_bar p {
    margin: 18px 0 0;
}
.about_banner.portfolio {
    padding: 6rem 0 0;
}
.review #review_slider h3 {
    font-size: 19px;
    margin: 1rem 0 7px;
}
.contact_page .contact_frm p {
    margin: 22px 0;
    font-size: 17px;
}





.about_banner.blog .container-fluid .txt {
    padding: 1rem;
}
.blog_card .item h3 {
    font-size: 24px;
}
.blog_card .item p {
    font-size: 14px;
    margin: 7px 3px;
    line-height: 22px;
}
.blog_card .item img {
    height: 224px;
}
.blog_card2 .item img {
    height: 278px;
}
.old_blog .left_txt .crd {
    display: block;
}
.old_blog .left_txt h4 {
    font-size: 38px;
    margin: 0 0 30px 0;
}
.old_blog .left_txt {
    height: 100%;
    overflow: inherit;
}
.old_blog .left_txt .crd img {
    width: 100%;
    height: 194px;
}
.old_blog .right_txt .category_btn {
    grid-template-columns: repeat(2,1fr);
    gap: 10px;
}
.old_blog .right_txt .category_btn a {
    padding: 6px;
    font-size: 15px;
}
.old_blog .frm form input {
    padding: 13px 20px;
    font-size: 16px;
}
.old_blog .frm form button {
    right: -4%;
    top: 31%;
    font-size: 17px;
}
.old_blog .talk {
    display: block;
    padding: 2rem;
     margin: 0 0 3rem 0;
}
.old_blog .talk img{
    display: none;
}
.old_blog .talk .txt h3 {
    font-size: 38px;
}
.old_blog .right_txt h4 {
    font-size: 29px;
    margin: 24px 0;
}
.old_blog .left_txt .crd h5 {
    font-size: 23px;
}
.old_blog .talk .txt a {
    padding: 7px 15px;
    font-size: 14px;
}

.modal-body-form p {
    margin: 18px 0;
    font-size: 11px;
}
.modal-body-form .popup-form-list .form-group input::placeholder,
.modal-body-form .popup-form-list .form-group textarea::placeholder{
    font-size: 12px;

}
.modal-body-form .popup-form-list .form-group input {
    font-size: 12px;
    padding: 12px 15px;
    margin: 4px 0;
}
.modal-body-form .popup-form-list .form-group button.submit-btn {
    font-size: 14px;
    width: 100%;
    padding: 6px 10px;
    margin: 0px 0 19px;
}

.modal-body-form .popup-form-list .form-group label {
    position: absolute;
    left: 17px;
    top: -5px;
    padding: 3px;
    font-size: 10px;
}
}