.contact_page{}
.contact_page .img_area{}
.contact_page .img_area img{
    width: 82%;
    margin: 0 auto;
    display: block;
}

.contact_page{
    padding: 13rem 0 0;
    background: #0F1115;
}
.contact_page .contact_frm{}
.contact_page .contact_frm h4{
    font-family: 'GlancyrBold';
    font-size: 62px;
    color: #ffffff;
    line-height: 32px;
    margin-bottom: 24px;
}

.contact_page .contact_frm p {
    color: #fff;
    font-family: 'NoirLight';
    margin: 30px 0;
    font-size: 22px;
}

.contact_page .contact_frm  h4 span {color: #ff6f1f;}
.contact_page .contact_frm  form{
    font-family: 'NoirLight';
    position: relative;
}
.contact_page .contact_frm form label{
    position: absolute;
    margin-top: -4px;
    left: 28px;
    background: #1a1a1a;
    padding: 4px 10px;
    font-size: 12px;
    color: #fff;
}
.contact_page .contact_frm  form input{
    width: 100%;
    padding: 20px 20px;
    margin: 17px 0;
    border: 2px solid #939dca45;
    border-radius: 3px;
    background: #1a1a1a;
}
.contact_page .contact_frm  form textarea{
    width: 100%;
    padding: 13px 20px;
    margin: 6px 0;
    border: 2px solid #939dca45;
    border-radius: 3px;
    height: 185px;
    background: #1a1a1a;
}
.contact_page .contact_frm  form textarea::placeholder,
.contact_page .contact_frm  form input::placeholder{}
.contact_page .contact_frm  form button{
    width: 176px;
    display: inline-block;
    text-align: center;
    font-weight: 700;
    line-height: 51px;
    font-size: 22px;
    transition: all 0.3s ease-in;
    border-radius: 4px;
    border: 2px solid #895de51f;
    margin: 7px 0;
    color: #000;
    background: #fff;
    font-family: 'NoirLight';
}
.contact_page .contact_frm  form button:hover{
    background: #ff6f1f;
    color: #fff;
}