* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}
main{
  overflow-x: hidden; 
}
.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{
  box-shadow: none;
  border: none;
  background: none;
}
:root{
    --primary-bg:#1A1A1A;
    --footer-bg:linear-gradient(143.93deg, rgba(22, 22, 24, 0.05) 1.73%, rgba(244, 128, 36, 0.1) 97.22%), #222224;;
    --font-color::#1A1A1A;
    --font-primary:'Exo', sans-serif;
    --font-secondary:'Inter', sans-serif;
    --shade-color:#ec790f;
}
.nav-link:focus, .nav-link:hover {
    color: var(--shade-color);
}
body {
  overflow-x: hidden;
  background:url('../assets/images/bg.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 0%;
}
a {
  text-decoration: none;
}
ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

input:focus,
textarea:focus {
  outline: none;
  box-shadow: none;
}

button:focus {
  outline: none;
  box-shadow: none;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(45deg, #2251fc, #955ee2);
  border: 1px solid #fff;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.container-fluid{padding: 0 6rem;}