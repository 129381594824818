.about_banner.services .txt h1 {
   font-size: 69px ;
}
.about_banner.services .txt span {
    color: #fff;
    font-family: 'GlancyrRegular';
    font-size: 21px;
    background: var(--shade-color);
    padding: 7px;
    border-radius: 9px;
    margin-bottom: 15px;
    display: block;
    width: 39%;
    text-align: center;
}
 /* services_form */
 .about_banner.services .container-fluid {
    background: transparent;
}
.about_banner.services h6 {
    font-family: 'NoirRegular';
    font-size: 17px;
    color: var(--primary-bg);
    margin: 36px 20px 0;
}
.about_banner.services h6 b{
    color: var(--shade-color);
}
.services_form{
    background: #fff;
    padding: 3rem;
    width: 76%;
    margin: 0 auto;
    display: block;
    border-radius: 7px;
    box-shadow: 2px 2px 39px #00000012;
}
.services_form h4{
    font-family: 'GlancyrBold';
    font-size: 32px;
    text-align: center;
    color: var(--shade-color);
    line-height: 35px;
    margin-bottom: 24px;
}

.services_form h4 span {
    display: block;
}
.services_form form{
    font-family: 'NoirLight';
    position: relative;
}
.services_form form label{
    position: absolute;
    margin-top: -4px;
    left: 28px;
    background: white;
    padding: 4px 10px;
    font-size: 12px;
}
.services_form form input{
    width: 100%;
    padding: 16px 20px;
    margin: 9px 0;
    border: 2px solid #939dca45;
    border-radius: 3px;
}
.services_form form textarea{
    width: 100%;
    padding: 13px 20px;
    margin: 6px 0;
    border: 2px solid #939dca45;
    border-radius: 3px;
    height: 165px;
}
.services_form form textarea::placeholder,
.services_form form input::placeholder{}
.services_form form button{
    width: 176px;
    display: inline-block;
    text-align: center;
    height: 51px;
    font-weight: 700;
    line-height: 51px;
    font-size: 22px;
    transition: all 0.3s ease-in;
    border-radius: 4px;
    border: 2px solid #895de51f;
    margin: 7px 0;
    color: #fff;
    background: var(--primary-bg);
}
.services_form form button:hover{}
 /* services_form  */
/* cta_banner */
.awards.services{
    background: linear-gradient(249deg, #f8f9ff, #f3f4f9);
}
.cta_banner.services{
   background: #fff;
    margin: 0;
}
 .cta_banner.services .cta_content h2 span {
    display: inline-block;
}
 .cta_banner.services .cta_content h2 {
    font-size: 66px;
    line-height: 91px;
    color:var(--primary-bg);
    width: 100%;
}
 .cta_banner.services  .cta_content p {
    font-size: 17px;
    margin: 12px 0 16px;
    line-height: 26px;
    color:var(--primary-bg);
}
.cta_banner.services .btns {
    margin: 31px 0 0;
}
.cta_banner.services .btns a{color: var(--primary-bg) !important;margin: 0 5px 0 0 !important;}
.cta_banner.services .btns a:nth-child(1){
    background: var(--primary-bg) !important;
    border: 1px solid #1a1a1a;
    color: #fff !important;
}
.cta_banner.services .cta_img img {
    width: 100%;
}
/* cta_banner */


/* process  */

.process_area.services{
    margin: 3rem 0;
}
.process_area.services .txt h2 {
    font-size: 96px;
}
.process_area.services .txt h2 span {
    display: inline-block;
    margin: 0 27px;
}
.process_area.services #process_slider{}
.process_area.services #process_slider .item{
      box-shadow: 2px 2px 57px #00000024;
      height: 540px;
      background: #fff;
      padding: 2rem;
      transition: all 0.4s ease-in;
      border-radius: 6px;
}

.process_area.services #process_slider .item:hover {
    background: #1a1a1a;
}

.process_area.services #process_slider .item:hover h3,
.process_area.services #process_slider .item:hover p,
.process_area.services #process_slider .item:hover .btns i {
    color: #fff;
}
.process_area.services #process_slider .item:hover .btns a{
    color: var(--shade-color);
}
.process_area.services #process_slider .item:hover img{
    filter: brightness(0) saturate(100%) invert(87%) sepia(0%) saturate(7491%) hue-rotate(151deg) brightness(125%) contrast(98%);
}
.process_area.services #process_slider .item h3{
    font-family: 'GlancyrBold';
    font-size: 38px;
    color: var(--primary-bg);
}
.process_area.services #process_slider .item p{
    font-family: 'NoirRegular';
    font-size: 16px;
    margin: 23px 3px;
    width: 97%;
    line-height: 26px;
}
.process_area.services #process_slider .item .btns{
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    font-family: 'NoirRegular';
    bottom: 41px;
    right: 0;
    margin: 0 37px;
}
.process_area.services #process_slider .item .btns a{
    width: 200px;
    display: inline-block;
    height: 51px;
    font-weight: 700;
    line-height: 51px;
    font-size: 18px;
    color: #1a1a1a;
    transition: all 0.3s ease-in;
    border-radius: 4px;
    margin: 0 11px;
    text-decoration: underline;
}
.process_area.services #process_slider .item .btns a i{
    font-size: 18px;
}
.process_area.services #process_slider .item .btns i{
    font-size: 101px;
    color: #000;
}
.process_area.services #process_slider .item .btns  img{
    width: 87px;
}
.process_area.services  .owl-carousel .owl-nav.disabled{
    display: block;
    position: absolute;
    top: -63px;
    right: 0;
}
.process_area.services  .owl-carousel .owl-nav{}
.process_area.services  .owl-carousel .owl-nav button{
    background: transparent;
    border: 2px solid var(--primary-bg);
    margin: 0 5px;
    border-radius: 4px;
    width: 58px;
    padding: 0;
    height: 44px;
}
.process_area.services  .owl-carousel .owl-nav button:hover{
    background:  var(--primary-bg);
}
.process_area.services  .owl-carousel .owl-nav button:hover span{
    color: #fff;
}
.process_area.services .owl-carousel .owl-nav button span {
    color: #000;
    font-size: 63px;
    margin: 0;
    padding: 0;
    line-height: 0;
    display: block;
    height: 0;
    position: relative;
    top: -9px;
}
.process_area.services  .owl-carousel .owl-nav button i{}
/* process  */


/* portfolio  */

.portfolio{}
.portfolio h4{
    font-family: 'GlancyrBold';
    font-size: 79px;
    color: var(--primary-bg);
    text-align: center;
    margin: 0 0 3rem;
}
.portfolio h4 i{
    color: var(--shade-color);
}
.portfolio .portfolio_grid img{
    width: 100%;
    object-fit: cover;
    transition: all 0.3s ease-in;
    height: 900px;
}

.portfolio .portfolio_grid img:hover{
    opacity: 0.8;
}
.portfolio .portfolio_grid {
    display: grid;
    grid-template-columns: repeat(3,1fr);
}
/* portfolio  */