.blog_card{}
.blog_card .item{
    box-shadow: 1px 1px 20px #0000003b;
    border-radius: 7px;
    overflow: hidden;
    margin: 20px 4px;
    background: #fff;
}
.blog_card .item img{
    width: 100%;
    height: 294px;
    object-fit: cover;
}
.blog_card .item h3{
    font-family: 'GlancyrBold';
    font-size: 33px;
    color: var(--primary-bg);
    display: flex;
    justify-content: space-between;
}

.blog_card .item h3 a {
    color: #000;
    transform: rotate(-45deg);
    font-weight: 800;
    font-size: 32px;
}
.blog_card .item p{
    font-family: 'NoirRegular';
    font-size: 16px;
    margin: 23px 3px;
    width: 100%;
    line-height: 26px;
}
.blog_card .item a{}
.blog_card .item a:hover{}
.blog_card .item span{
    font-family: 'NoirRegular';
    font-size: 14px;
    margin: 23px 3px;
    line-height: 26px;
    color: #858585a1;
}
.blog_card .item span b{
    color: #000;
    margin: 0 15px 0 0;
}
.about_banner.blog{
    padding: 6rem 0 5rem;
}

/* blog_card2  */
.blog_card2{}
.blog_card2 .item{
    box-shadow: 1px 1px 20px #0000003b;
    border-radius: 7px;
    overflow: hidden;
    margin: 20px 4px;
    background: #1a1a1a;
    height: 582px;
    transition: all 0.3s ease-in;
}

.blog_card2 .item:hover {
    transform: translateY(-10px);
}
.blog_card2 .item img{
    width: 100%;
    height: 332px;
    object-fit: cover;
}
.blog_card2 .item h3{
    font-family: 'GlancyrBold';
    font-size: 27px;
    color: #fff;
    display: flex;
    justify-content: space-between;
}

.blog_card2 .item h3 a {
    color: #000;
    transform: rotate(-45deg);
    font-weight: 800;
    font-size: 23px;
}
.blog_card2 .item p{
    font-family: 'NoirRegular';
    font-size: 16px;
    margin: 23px 3px;
    width: 100%;
    line-height: 26px;
    color: #fff;
}
.blog_card2 .item a{}
.blog_card2 .item a:hover{}
.blog_card2 .item span{
    font-family: 'NoirRegular';
    font-size: 12px;
    margin: 23px 3px;
    line-height: 26px;
    color: #fff;
}
.blog_card2 .item span b{
    color: var(--shade-color);
    margin: 0 3px 0 0;
}
/* blog_card2  */


/* old_blog  */
.old_blog{
    margin: 4rem 0 0;
}
.old_blog .left_txt{
    height: 79%;
    overflow-y: scroll;
    overflow-x: hidden;
}
.old_blog .left_txt h4{
    font-family: 'GlancyrBold';
    font-size: 50px;
    color: var(--primary-bg);
    margin: 0 0 45px 0;
}
.old_blog .left_txt .crd{}
.old_blog .left_txt .crd{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #80808066;
}
.old_blog .left_txt .crd img{
    width: 226px;
    height: 167px;
    object-fit: cover;
    margin: 16px  22px 16px 0;
    border-radius: 5px;
}
.old_blog .left_txt .crd h6{
    font-family: 'GlancyrMedium';
    margin: 0 0 14px 0;
}
.old_blog .left_txt .crd h5{
    font-family: 'GlancyrBold';
    font-size: 28px;
    color: var(--primary-bg);
    display: flex;
    justify-content: space-between;
}
.old_blog .left_txt .crd h5 a{}
.old_blog .left_txt .crd h5 a{
    color: var(--shade-color);
    transform: rotate(-45deg);
    font-weight: 800;
    font-size: 32px;
}
.old_blog .left_txt .crd p{
    font-family: 'NoirRegular';
    font-size: 16px;
    margin: 13px 3px;
    width: 100%;
    line-height: 26px;
}
.old_blog .left_txt .crd span {
    font-family: 'NoirRegular';
    font-size: 14px;
    margin: 23px 3px;
    line-height: 26px;
    color: #858585a1;
}
.old_blog .left_txt .crd span b{
    color: #ec790f;
}

.old_blog .right_txt{}
.old_blog .right_txt h4{
    font-family: 'GlancyrBold';
    font-size: 45px;
    color: var(--primary-bg);
    margin: 0 0 45px 0;
}
.old_blog .right_txt .category_btn{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 18px;
}
.old_blog .right_txt .category_btn a{
    border: 2px solid #1a1a1a;
    text-align: center;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    color: #1a1a1a;
    font-family: 'NoirRegular';
    transition: all 0.3s ease-in;
    font-size: 21px;
}
.old_blog .right_txt .category_btn a:hover{
    color: #fff;
    background: linear-gradient(45deg, #2251fc, #955ee2);
}
.old_blog .frm{
    margin: 37px 0;
    position: relative;
}
.old_blog  .frm form{}
.old_blog  .frm form input{
    width: 100%;
    padding: 17px 20px;
    border: none;
    border-radius: 10px;
    background: white;
    font-family: 'NoirRegular';
    font-size: 21px;
    text-transform: uppercase;
    border: 2px solid #1a1a1a;
}
.old_blog  .frm form button {
    position: absolute;
    right: 1%;
    top: 23%;
    transform: translate(-1%,-10%);
    border: none;
    width: 100px;
    background: transparent;
    font-size: 29px;
}
.old_blog  .frm form button i{}

.old_blog .talk{
    background: #000;
    display: flex;
    padding: 2rem 2rem;
    border-radius: 10px;
}

.old_blog .talk img {
    width: 183px;
}
.old_blog .talk .txt{}
.old_blog .talk .txt h3{
    font-family: 'GlancyrBold';
    font-size: 43px;
    color: #fff;
}
.old_blog .talk .txt h3 span{
    color: var(--shade-color);
}
.old_blog .talk .txt p{
    font-family: 'NoirRegular';
    font-size: 16px;
    margin: 13px 3px 22px;
    width: 100%;
    line-height: 29px;
    color: #fff;
}
.old_blog .talk .txt a{
    border: 2px solid #ec790f;
    text-align: center;
    padding: 10px 24px;
    border-radius: 5px;
    color: #ec790f;
    font-family: 'NoirRegular';
    transition: all 0.3s ease-in;
    font-size: 21px;
    background: white;
}
.old_blog .talk .txt a:hover{}
/* old_blog  */