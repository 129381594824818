/* .about_banner  */
.about_banner{padding: 6rem 0;}
.about_banner .container-fluid{
        background: #F5F5F5;
        padding: 6rem 6rem;
}
.about_banner .txt{}
.about_banner .txt span{
    color: var(--shade-color);
    font-family: 'GlancyrBold';
    font-size: 33px;
}
.about_banner .txt p{
    font-family: 'NoirRegular';
    font-size: 19px;
    margin: 13px 10px;
    width: 83%;
    line-height: 28px;
}
.about_banner .txt h1{
    font-family: 'GlancyrBold';
    font-size: 112px;
    color: var(--primary-bg);
}
.about_banner .txt h1 i{
    color: var(--shade-color);
}
.about_banner .btns{
    display: flex;
    font-family: 'NoirLight';
    margin-top: 32px;
}
.about_banner .btns a{
    width: 200px;
    display: inline-block;
    text-align: center;
    height: 51px;
    font-weight: 700;
    line-height: 51px;
    font-size: 18px;
    color: var(--font-color);
    transition: all 0.3s ease-in;
    border-radius: 4px;
    border: 2px solid #895de51f;
    margin: 0 11px;
}
.about_banner .btns a:nth-child(1){
        background: var(--primary-bg);
        color: #fff;
}
.about_banner .btns a i{
    transform: rotate(45deg);
    font-size: 23px;
    font-weight: 600;
    margin: 0 6px;
    color: #4CAF50;
}
.about_banner .btns a:hover{
    transform: translateY(-10px);
}

.about_banner .img_banner{}
.about_banner .img_banner img{
    width: 100%;
}


/* .about_banner  */

/* mvv_wrap  */
.mvv_wrap{
    margin: 2rem 0;
}
.mvv_wrap .item{
    box-shadow: 2px 2px 57px #00000024;
    height: 500px;
    background: #fff;
    padding: 3rem;
    transition: all 0.4s ease-in;
    border-radius: 6px;
}
.mvv_wrap .item:hover{
    background: #1A1A1A;
}
.mvv_wrap .item:hover h3,
.mvv_wrap .item:hover p,
.mvv_wrap .item:hover h6{
    color: #fff;
}
.mvv_wrap .item h3{
    font-family: 'GlancyrBold';
    font-size: 50px;
    color: var(--primary-bg);
}
.mvv_wrap .item h3 i{
    color: var(--shade-color);
}
.mvv_wrap .item h6{
    font-family: 'NoirMedium';
    font-size: 25px;
    margin: 16px 10px;
    width: 83%;
    line-height: 32px;
}
.mvv_wrap .item p{
    font-family: 'NoirRegular';
    font-size: 16px;
    margin: 12px 10px;
    width: 83%;
    line-height: 22px;
}
.crowned {
    background: #fff;
    padding: 3rem 0  0rem !important;
} 
/* mvv_wrap  */

/* awards  */
.awards.about {
    background: #1A1A1A !important;
}
.awards.about .txt h5,
.awards.about .txt p,
.awards.about .rating h5,
.awards.about .rating h4{
    color: #fff !important;
}
.awards.about #slider_award img {
    padding: 53px !important;
    width: 331px !important;
    background: #fff !important;
    height: 327px !important;

}
/* awards  */

/* counter  */
.counter{
    padding: 3rem 0;
    background: whitesmoke;
}
.counter .count_data{
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}
.counter .count_data h4{position: relative;font-family: 'GlancyrBold';font-size: 101px;color: var(--primary-bg);line-height: 72px;margin: 23px 0;}
.counter .count_data p{
    font-size: 18px;
    font-family: 'NoirRegular';
    color: #000;
    line-height: 31px;
    margin: -9px auto;
}
.counter .count_data h4::after{
    content: '+';
    position: absolute;
    top: 0;
    right: -30px;
    font-size: 73px;
}
/* counter  */


/* media  */
.media_mention{
    padding: 5rem 0;
}
.media_mention .txt_head{
    text-align: center;
}
.media_mention .txt_head h5{
        font-family: 'GlancyrBold';
    font-size: 61px;
    color: var(--primary-bg);
}
.media_mention .txt_head h5 span{
    color: var(--shade-color);
}
.media_mention .txt_head p{
    font-family: 'NoirRegular';
    font-size: 17px;
    margin: 11px 0 2rem;
}
.crds{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.crds .crd{
    transition: all 0.3s ease-in-out;
    background: #fff;
    padding: 3rem 2rem;
    text-align: center;
    margin: 0 9px;
    border-radius: 2px;
}
.crds .crd:hover{
    transform: scale(1.1);
}
.crds .crd img{
    width: 236px;
}
.crds .crd p{
    font-size: 18px;
    font-family: 'NoirRegular';
    color: #000;
    line-height: 31px;
    margin: 20px 0 0;
}
/* media  */
.cta_banner.about{
    background:#000 !important;
    padding: 5rem 0 8rem !important;
    margin: 0 !important;
}
.cta_banner.about .cta_img img {
    width: 94% !important;
    top: -6rem;
}